import React from 'react'
import SEO from "../components/atoms/seo"

import Layout from '../components/organisms/layout'
import Heading from '../components/molecules/heading'
// import BodyCopy from '../components/atoms/body-copy'

import content from "../data/pages/questionnaire/content.json"

const ThankYouPage = () => (
  <Layout hideSecureForm>
    <SEO title='Thank You' />
    <Heading 
        title='Thank you for completing your hearing questionnaire!'
        subTitle='We will call you within 24 hours to answer your questions and build a personalized recommendation — you’ll be hearing better in no time!'
        weight='light'
    />
  </Layout>
)

export default ThankYouPage
